import React, { useContext, useEffect, useState } from 'react'
import styles from './FixtureTicker.module.scss'

import closeSvg from "./cross-circle-svgrepo-com.svg"
import { Fixture, Team } from '../../../../domain/model'
import { teams } from '../constants'
import FutureFixtures from '../FutureFixtures/FutureFixtures'

import arrowLeft from "./arrow-left.svg"
import arrowRight from "./arrow-right.svg"
import { FDRContext } from '../../Hooks/FDRContext'
import { useDetermineDifficulty } from '../../Hooks/useDetermineDifficulty'
import { usePlausibleEvent } from '../../Hooks/usePlausibleEvent'
import CustomFDRSettings from '../CustomFDRSettings/CustomFDRSettings'

type FixtureTickerProps = {
    fixtures: Fixture[]
    fromGw: number
}



type Sorting = {
    sorting: "difficulty" | "name"
    order: "asc" | "desc"
}

const sortings = [
    {
        sorting: "difficulty",
        order: "asc"
    },
    {
        sorting: "difficulty",
        order: "desc"
    },
    {
        sorting: "name",
        order: "asc"
    },
    {
        sorting: "name",
        order: "desc"
    }
]

export default function FixtureTicker({ fixtures, fromGw }: FixtureTickerProps) {
    const savedSorting = localStorage.getItem("sorting")
    const savedSortingObject = savedSorting ? JSON.parse(savedSorting) : { sorting: "difficulty", order: "asc" }
    const savedFdrType = localStorage.getItem("fdrType")
    const savedGwAmount = localStorage.getItem("gwAmount")

    const [sorting, setSorting] = useState<Sorting>(savedSorting ? savedSortingObject as Sorting : { sorting: "difficulty", order: "asc" })
    const [initialGw, setInitialGw] = useState(fromGw)
    const [fdrType, setFdrType] = useState<'attack' | 'defense'>(savedFdrType ? savedFdrType as 'attack' | 'defense' : 'defense')

    const [gwAmount, setGwAmount] = useState(savedGwAmount ? parseInt(savedGwAmount) : 6)

    const { determineDifficulty } = useDetermineDifficulty()

    const { teams: teamObjects, fdrSetting, setFDRSetting } = useContext(FDRContext)


    const [teams, setTeams] = useState(teamObjects.map(team => ({ ...team })))
    const [removedTeams, setRemovedTeams] = useState<string[]>([])

    const sortedTeams = sortTeams()

    const [showCustomFDRSettings, setShowCustomFDRSettings] = useState(false)

    useEffect(() => {
        localStorage.setItem("sorting", JSON.stringify(sorting))
    }, [sorting])

    useEffect(() => {
        localStorage.setItem("fdrType", fdrType)
    }, [fdrType])

    useEffect(() => {
        localStorage.setItem("gwAmount", gwAmount.toString())
    }, [gwAmount])


    const gwAmounts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    function getAverageFixtureDifficulty(team: Team, fdrFor: 'attack' | 'defense') {
        const teamFixtures = fixtures
            .filter(fixture => (fixture.team_a.id === team.id || fixture.team_h.id === team.id) && fixture.event.id < (initialGw + gwAmount) && fixture.event.id >= initialGw)
        let totalDifficulty = 0
        teamFixtures.forEach(fixture => {
            const fixtureDifficulty = determineDifficulty(team, fixture, fdrFor)
            totalDifficulty += fixtureDifficulty

        })


        return totalDifficulty / teamFixtures.length
    }

    const gameWeeks = [...Array(gwAmount).keys()].map(i => initialGw + i < 39 ? initialGw + i : null).filter(event => event !== null)

    function sortTeams() {
        const sorted = teams.map(team => {
            const averageDifficulty = getAverageFixtureDifficulty(team, fdrType)

            return {

                ...team,
                averageDifficulty: averageDifficulty
            }
        }).sort((a, b) => {
            const first = sorting.sorting === "difficulty" ? a.averageDifficulty : a.short_name
            const second = sorting.sorting === "difficulty" ? b.averageDifficulty : b.short_name
            switch (sorting.order) {
                case "asc":
                    return first > second ? 1 : -1
                case "desc":
                    return first > second ? -1 : 1
            }
        })

        return sorted
    }




    function nextGameWeek() {
        if (initialGw < 39 - gwAmount) {
            setInitialGw(initialGw + 1)
        }
    }

    function resetTeams() {
        if (removedTeams.length > 0) {
            setRemovedTeams([])
        }
    }

    function previousGameWeek() {
        if (initialGw > 1) {
            setInitialGw(initialGw - 1)
        }
    }

    function handleGwAmountChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setGwAmount(parseInt(event.target.value))
    }

    function handleSortingChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const [sorting, order] = event.target.value.split(" ")
        setSorting({ sorting: sorting as "difficulty" | "name", order: order as "asc" | "desc" })

    }

    function handleFdrTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
        setFdrType(event.target.value as 'attack' | 'defense')
    }

    return (
        <section className={styles.mainContainer}>

            


            <table className={styles.table}>
            {showCustomFDRSettings && <CustomFDRSettings closeCustomFDRSettings={() => setShowCustomFDRSettings(false)} />}
                <thead>
                    <th className={styles.teamHead}></th>
                    <th className={styles.fixturesHead}>

                        <div className={styles.gwContainer}>
                            {
                                gameWeeks.map((gw) => {
                                    return (
                                        <div className={styles.gw} key={gw} >
                                            <h4 >{gw}</h4>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </th>
                </thead>

                <tbody>
                    {
                        sortedTeams.map((team) => {
                            if (removedTeams.includes(team.short_name)) {
                                return null
                            }
                            return (
                                <tr className={styles.tableRow} key={team.id}>
                                    <td className={styles.teamName}>
                                        <div className={styles.nameAndBtn}>

                                            <img onClick={() => {
                                                setRemovedTeams([...removedTeams, team.short_name])
                                            }} src={closeSvg} alt="" />
                                            <span>{team.short_name}</span>
                                        </div>
                                    </td>
                                    <td className={styles.fixtures}>
                                        <FutureFixtures fdr={fdrType} fixtures={fixtures} fromEvent={initialGw} toEvent={initialGw + gwAmount < 39 ? initialGw + gwAmount : 39} team={team} />
                                    </td>
                                </tr>
                            )
                        })
                    }

                </tbody>


            </table>
            <section className={styles.tickerControls}>
                <div className={styles.controlContainer}>
                    <p>gws ahead</p>
                    <select className={styles.gwAmountSelect} name="gwAmount" id="gwAmount" onChange={handleGwAmountChange}>

                        {
                            gwAmounts.map(amount => {
                                return <option selected={amount === gwAmount} key={amount} value={amount}>{amount}</option>
                            })
                        }
                    </select>

                </div>
                <div id={styles.noGrow} className={`${styles.controlContainer} ${styles.noGrow}`}>
                    <p>change gw</p>
                    <div className={styles.flexRow}>
                        <button className={styles.arrowBtn} onClick={previousGameWeek}><img src={arrowLeft} alt="" /></button>
                        <button className={styles.arrowBtn} onClick={nextGameWeek}><img src={arrowRight} alt="" /></button>
                    </div>


                </div>


                <div className={styles.controlContainer}>
                    <p>sort by</p>
                    <select className={styles.gwAmountSelect} onChange={handleSortingChange} name="sorting" id="sorting">
                        {
                            sortings.map(option => {
                                return <option selected={option.sorting === sorting.sorting && option.order === sorting.order} key={option.sorting + option.order} value={option.sorting + " " + option.order}>
                                    {option.sorting} {option.order === "asc" ? "\u2191" : "\u2193"}</option>
                            })
                        }
                    </select>
                </div>
                <div className={`${styles.controlContainer} ${styles.noGrow}`}>
                    <p>reset teams</p>
                    <button onClick={resetTeams} className={`${styles.resetBtn} ${removedTeams.length > 0 && styles.active}`}>reset</button>
                </div>
                <div className={styles.controlContainer}>
                    <p>select FDR</p>
                    <select className={styles.gwAmountSelect} name="fdrSetting" id="fdrSetting" onChange={(e) => setFDRSetting(e.target.value as "standard" | "fplstrat")}>
                        <option selected={fdrSetting === "standard"} value="standard">FPL</option>
                        <option selected={fdrSetting === "fplstrat"} value="fplstrat">fplstrat xG based</option>
                        <option selected={fdrSetting === "custom"} value="custom">custom</option>
                    </select>
                </div>
                { fdrSetting === "custom" && (
                    <div className={`${styles.controlContainer} ${styles.noGrow}`}>
                        <p>custom FDR settings</p>
                        <button onClick={() => setShowCustomFDRSettings(true)} className={`${styles.resetBtn} ${styles.active}`}>open</button>
                        </div>
                )}
                {
                    (fdrSetting === "fplstrat" || fdrSetting === "custom") && (
                        <div className={styles.controlContainer}>
                            <p>difficulty for</p>
                            <select className={styles.gwAmountSelect} onChange={handleFdrTypeChange} name="difficultyFor" id="difficultyFor">
                                <option selected={fdrType === "defense"} value="defense">defense</option>
                                <option selected={fdrType === "attack"} value="attack">attack</option>
                            </select>
                        </div>
                    )}




            </section>

        </section>
    )
}

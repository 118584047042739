import styles from './HeroPage.module.scss'

import { Link } from 'react-router-dom'

import { StandardLayout } from '../StandardLayout/StandardLayout'

function HeroPage() {
    return (
        <StandardLayout
            canonicalUrl='https://fplstrat.app'
            metaDescription='The ultimate free FPL Transfer Planner! Outcompete your Fantasy Premier League rivals with xG-based FDR and a powerful fixture ticker!'
            metaTitle='FPL Transfer Planner'>



            <section className={styles.mainSection}>
                <section className={styles.heroTitleSection}>
                    <h1 className={styles.heroTitle}>the <span className={styles.ultimate}>ultimate</span> <br></br> FPL Transfer Planner</h1>
                    <h2 className={styles.heroSubtitle}>
                        The <span className={styles.highlight}>free</span> fplstrat FPL Transfer Planner helps you <span className={styles.highlight}>catch those green arrows 📈📈! </span>
                        Featuring the <span className={styles.highlight}>best Planning UI</span>,
                        a <span className={styles.highlight}>powerful Fixture Ticker</span>, a <span className={styles.highlight}>smart Stat Overview for Players</span> and


                        <span className={styles.highlight}> (optional) xG based FDR</span>, click
                        <span className={styles.highlight}> start planning</span>  <span className={styles.highlight}>and turn your Fantasy Premier League season around!</span>

                    </h2>



                    <div className={styles.btnContainer}>
                        <Link className={`${styles.startPlanningBtn} plausible-event-name=click+start+planning`} to="/planner" >{"->"} start planning!</Link>

                    </div>


                </section>

                <section className={styles.screenshots}>

                    <section className={styles.heroImageSection}>

                        <img className={styles.screenshot} srcSet="screenshot-sm.webp 224w, screenshot.webp 618w"
                            sizes="(min-width: 450px) 618px, 224px"
                            alt="fpl-transfer-planner screenshot" />
                        <p><Link to={"/planner"}>planner</Link> with stats overview opened</p>
                    </section>

                    <section className={styles.heroImageSection}>
                        <img className={styles.screenshot} srcSet="ticker-screenshot-sm.webp 224w, ticker-screenshot.webp 618w"
                            sizes="(min-width: 450px) 618px, 224px"
                            alt="fpl-fixture-ticker screenshot" />
                        <p><Link to={"/fpl-fixture-ticker"}>fixture ticker</Link> with xG based FDR enabled</p>

                    </section>
                </section>

            </section>


        </StandardLayout>
    )
}

export default HeroPage